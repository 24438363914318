import React, { useState } from 'react';
import { Button, Group, Text, Image, Grid, AppShell, Stack } from '@mantine/core';
import { searchMoAs as apiSearchMoa } from '../../services/moas';
import { searchIndications as apiSearchIndications } from '../../services/indication';
import SearchField from '../SearchField';
import MultiSearchField from '../MultiSearchField';
import bi_logo from '../../assets/logo/bi.svg'
import jku_logo from '../../assets/logo/jku-dark.png'
import vds_logo from '../../assets/logo/vds-dark.png'
import { PiTreeView } from 'react-icons/pi';

const Header = ({ onMeshSwitched }: { onMeshSwitched: () => void }) => {
    const [meshOpened, setMeshOpened] = useState(false)

    const searchMoA = async (q: string) => {
        return await apiSearchMoa(q);
    }

    const searchIndication = async (q: string) => {
        return await apiSearchIndications(q);
    }

    const optionSelected = (option: string) => {
        console.log(option)
    }
    return (
        <AppShell.Header style={{ backgroundColor: "#34354a", padding: 12 }}>
            <Grid align='center' style={{ height: "100%" }}>
                <Grid.Col span={3}><Text size='xl' c="#f3f3f7" fw={500}>ProjectName</Text></Grid.Col>
                <Grid.Col span={6}>
                    <Stack gap={2} style={{ height: "100%" }}>

                        <Grid>
                            <Grid.Col span={11}>
                                <SearchField
                                    onSearch={searchMoA}
                                    onOptionSelect={optionSelected}
                                    placeholder='Search for an MoA'
                                    styles={{
                                        input: {
                                            backgroundColor: 'rgb(198,198,206, 0.2)',
                                            border: 0,
                                            color: "#f3f3f7"
                                        },
                                    }} />
                            </Grid.Col>
                            <Grid.Col span={1}>
                            </Grid.Col>
                        </Grid>

                        <Grid>
                            <Grid.Col span={11}>
                                <MultiSearchField
                                    onSearch={searchIndication}
                                    onOptionSelect={optionSelected}
                                    placeholder='Select indications'
                                    styles={{
                                        input: {
                                            backgroundColor: 'rgb(198,198,206, 0.2)',
                                            border: 0,
                                            color: "#f3f3f7"
                                        },
                                    }} />
                            </Grid.Col>
                            <Grid.Col span={1} style={{ padding: 0 }}>
                                <Button
                                    leftSection={<PiTreeView size={20} />}
                                    variant='transparent'
                                    style={{
                                        padding: 0,
                                        color: !meshOpened ? "#f3f3f7" : "#fcc419"
                                    }}
                                    onClick={() => {
                                        setMeshOpened(!meshOpened);
                                        onMeshSwitched();
                                    }}>
                                    MeSH
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Group justify="flex-end">
                        <Image src={jku_logo} style={{ maxHeight: "100%", maxWidth: "90px" }} />
                        <Image src={vds_logo} style={{ maxHeight: "100%", maxWidth: "90px" }} />
                        <Image src={bi_logo} style={{ maxHeight: "100%", maxWidth: "90px" }} />
                    </Group>
                </Grid.Col>
            </Grid>
        </AppShell.Header>
    );
};

export default Header;
