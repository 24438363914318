import React from 'react';
import { Text, Center } from '@mantine/core';

const Search: React.FC = () => {
  return (
    <Center style={{height:'100vh'}}>
      <Text style={{ fontSize: '2rem' }} fw={700} c="#e4e4e6" ta="center">
        Select a Mode of Action (MoA) to Start
      </Text>
    </Center>
  );
}

export default Search;
