import React, { useEffect, useRef } from "react"
import * as d3 from 'd3';

interface SvgComponentProps {
    render: (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => void;
    data: object
    width?: number;
    height?: number;
}

const SvgComponent: React.FC<SvgComponentProps> = ({ render, data, width = 500, height = 500 }) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        svg.append("rect")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("fill", "white");


        render(svg);

        return () => {
            svg.selectAll('*').remove();
        };
    }, [render, width, height, data]);

    return (
        <svg
            ref={svgRef}
            width={width}
            height={height}
        />
    );
};

export default SvgComponent;
