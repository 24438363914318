import React, { useEffect, useState } from "react";
import { Stack, Table } from "@mantine/core"
import * as d3 from 'd3';
import SvgComponent from "./SvgComponent";

export enum TxtMiningTypes {
    overall = 'overall',
    novelty = 'novelty'
}

interface Publication {
    link: string
    year: number
    is_new: boolean
}

interface DataPoint {
    id: number
    gene: string
    comentions: number
    odds_ratio: number
    value?: number
    publications: Publication[]
}

interface TxtMiningChartProps {
    type: TxtMiningTypes
    data: DataPoint[]
    filters: any[]
    width: number
    height: number
    onBarSelected: (d: DataPoint) => void
}

const TxtMiningChart: React.FC<TxtMiningChartProps> = ({ type, data, filters, width, height, onBarSelected }) => {
    const [selectedBar, setSelectedBar] = useState<number | null>(null)
    const [processedData, setProcessedData] = useState<DataPoint[]>([]);

    const meets = (filter, d) => {
        return filter.gene !== undefined ? d.gene === filter.gene : true;
    };

    useEffect(() => {
        const updatedData = data.map((d) => ({
            ...d,
            value: type === TxtMiningTypes.overall ? d.comentions : d.odds_ratio,
            greyed_out: !filters.some(f => meets(f, d))
        }));
        setProcessedData(updatedData);
    }, [type, data, filters]);

    const marginTop = 30;
    const marginRight = 0;
    const marginBottom = 30;
    const marginLeft = 70;

    const render = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
        const x = d3.scaleBand()
            .domain(d3.groupSort(processedData, ([d]) => -d.value, (d) => d.gene))
            .range([marginLeft, width - marginRight])
            .padding(0.2);

        const y = d3.scaleLinear()
            .domain([0, d3.max(processedData, (d) => d.value)])
            .range([height - marginBottom, marginTop]);

        svg.append("g")
            .selectAll()
            .data(processedData)
            .join("rect")
            .attr("fill", (d) => d.greyed_out ? "#ccc" : "steelblue")
            .attr("x", (d) => x(d.gene))
            .attr("y", (d) => y(d.value))
            .attr("height", (d) => y(0) - y(d.value))
            .attr("width", x.bandwidth())
            .attr("opacity", d => selectedBar === d.id ? 0.5 : 1)
            .on('click', (event, d) => {
                if (d.greyed_out) return
                if (selectedBar === d.id) setSelectedBar(null);
                else setSelectedBar(d.id)
                onBarSelected(d)
            });

        svg.append("g")
            .selectAll("text")
            .data(processedData)
            .join("text")
            .attr("text-anchor", "middle")
            .attr("x", (d) => x(d.gene) + x.bandwidth() / 2)
            .attr("y", (d) => y(d.value) - 5)
            .text((d) => d.value.toFixed(2))
            .style("font-size", "12px");

        svg.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)
            .call(d3.axisBottom(x).tickSizeOuter(0));

        svg.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y).ticks(0))
            .call(g => g.select(".domain").remove())
            .call(g => g.append("text")
                .attr("x", -height / 2)
                .attr("y", -marginLeft + 30)
                .attr("transform", "rotate(-90)")
                .attr("fill", "currentColor")
                .attr("text-anchor", "middle")
                .style("font-size", "14px")
                .text(type === TxtMiningTypes.overall ? 'Total Comentions' : 'Odds Ratio'));

    };

    return (
        <SvgComponent
            render={render}
            data={processedData}
            width={width}
            height={height} />
    );
};

interface TxtMiningDetailsProps {
    type: TxtMiningTypes
    data: DataPoint[]
    filters: any[]
    width: number
    height: number
}

const TxtMiningDetails: React.FC<TxtMiningDetailsProps> = ({ type, data, filters, width, height }) => {
    const [tabularData, setTabularData] = useState<DataPoint>(null)

    const onBarSelected = (d: DataPoint) => {
        if (tabularData && d.id === tabularData.id) setTabularData(null);
        else setTabularData(d)
    }

    return (
        <Stack>
            <TxtMiningChart
                type={type}
                data={data}
                filters={filters}
                width={width - 50}
                height={height}
                onBarSelected={onBarSelected}
            />
            {tabularData ? (
                <Table highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Link</Table.Th>
                            {type === TxtMiningTypes.novelty ? <Table.Th>Is New</Table.Th> : <></>}
                            <Table.Th>Year</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {tabularData.publications.map(d => (
                            <Table.Tr key={d.link}>
                                <Table.Td><a href={d.link} >{d.link}</a></Table.Td>
                                {type === TxtMiningTypes.novelty ? <Table.Td>{`${d.is_new}`}</Table.Td> : <></>}
                                <Table.Td>{d.year}</Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
            ) : <></>}
        </Stack>
    );
};

export default TxtMiningDetails;
