import React, { useState } from "react";
import { Stack, Table, List, Radio, ScrollArea, Title, Badge } from "@mantine/core"
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";


interface EvidenceType {
    type: string
    exists: boolean
}

interface DataPoint {
    id: number
    gene: string
    links: string[]
    evidence_type: EvidenceType[]
    greyed_out?: boolean
}

interface OtpDetailsProps {
    data: DataPoint[]
    filters: any[]
    width: number
    height: number
}

const OtpDetails: React.FC<OtpDetailsProps> = ({ data, filters, width, height }) => {
    const [tabularData, setTabularData] = useState<DataPoint>(null)

    const meets = (filter, d) => {
        return filter.gene !== undefined ? d.gene === filter.gene : true;
    };

    data = filters ? data.map((d) => {
        d.greyed_out = true
        if (filters.find(f => meets(f, d)))
            d.greyed_out = false
        return d
    }) : data;

    return (
        <Stack>
            <Stack>
                <ScrollArea>
                    <Table highlightOnHover>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th></Table.Th>
                                <Table.Th>Gene</Table.Th>
                                {data[0].evidence_type.map(e => (
                                    <Table.Th>{e.type}</Table.Th>
                                ))}
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {data.map(d => (
                                <Table.Tr key={d.id}
                                    style={{
                                        backgroundColor: d.greyed_out ? '#f0f0f0' : 'inherit',
                                        color: d.greyed_out ? '#a0a0a0' : 'inherit',
                                        pointerEvents: d.greyed_out ? 'none' : 'auto',
                                    }}>
                                    <Table.Td>
                                        <Radio
                                            aria-label="Select row"
                                            disabled={d.greyed_out}
                                            checked={tabularData?.id === d.id}
                                            onChange={(event) =>
                                                setTabularData(
                                                    event.currentTarget.checked ? d : null
                                                )
                                            }
                                        />
                                    </Table.Td>
                                    <Table.Td>{d.gene}</Table.Td>
                                    {d.evidence_type.map(e => (
                                        <Table.Td>{e.exists ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</Table.Td>
                                    ))}
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                    </Table>

                </ScrollArea>
                {tabularData ? (
                    <>
                        <Title order={4} style={{ color: "#4a5167" }}>Publications</Title>
                        <ScrollArea h={height}>
                            <List withPadding style={{ color: "#4a5167" }}>
                                {tabularData.links.map(l => (
                                    <List.Item>
                                        <a href={l} >{l}</a>
                                    </List.Item>
                                ))}
                            </List>
                        </ScrollArea>
                    </>
                ) : <></>}
            </Stack >
        </Stack>
    );
};

export default OtpDetails;
