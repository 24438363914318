export interface Indication {
    id: number;
    name: string;
}

export interface MOA {
    id: number;
    name: string;
}

export enum DataStreamType {
    TxtMiningOverall = "TxtMining_overall",
    TxtMiningNovelty = "TxtMining_novelty",
    DiffExpr = "DiffExpr",
    OTP = "OTP",
    OTG = "OTG"
}

export interface DetailedScore {
    type: DataStreamType;
    score: number;
}

export interface Association {
    indication: Indication;
    moa: MOA;
    m_score: number;
    detailed_scores: DetailedScore[];
}

export interface Run {
    version: string;
    default_w: { type: DataStreamType, value: number }[];
    default_a: { type: DataStreamType, value: number }[];
}