import React, { useState } from "react";
import { DataStreamType } from "../../types";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table"
import { Stack, Group, ColorSwatch, Text } from "@mantine/core"
import indications from "../../assets/data/indications.json"
import { BarChartHeader, StackedBarCell } from "../../components/Rankings/StackedBarCell";
import { useNavigate } from "react-router-dom";
import { css } from '@emotion/css';
import * as d3 from 'd3'

const hdata = [
    { name: DataStreamType.TxtMiningOverall, value: 0.35, color: '#2ca02c' },
    { name: DataStreamType.TxtMiningNovelty, value: 0.05, color: '#9467bd' },
    { name: DataStreamType.DiffExpr, value: 0.1, color: '#ff7f0e' },
    { name: DataStreamType.OTP, value: 0.2, color: '#d62728' },
    { name: DataStreamType.OTG, value: 0.3, color: '#715b64' }
]

const rows = indications
const domain: [number, number] = [
    d3.min(indications.map(d => d.detailed_scores), d => d3.min(d, dd => dd.value)),
    d3.max(indications.map(d => d.m_score), d => d3.max(d, dd => dd.value))
]

const Rankings = () => {
    const navigate = useNavigate()
    const [hoveredRowId, setHoveredRowId] = useState(null);

    const columns = [
        // {
        //     accessorKey: "empty0",
        //     cell: () => null,
        //     header: "",
        //     enableSorting: false,
        //     enableColumnFilter: false,
        //     enableColumnActions: false,
        //     size: 5,
        //     minSize: 1
        // },
        {
            accessorKey: "indication.name",
            header: "Indication",
            size: 100,
            enableSorting: false,
            enableColumnFilter: true,
            enableColumnActions: true,
            headerStyle: { borderBottom: '1px solid #ccc' },
        },
        {
            accessorKey: "empty1",
            cell: () => null,
            header: "",
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            size: 5,
            minSize: 1
        },
        {
            accessorKey: "m_score",
            header: "M_score",
            Cell: ({ cell }) => <StackedBarCell cell={cell} showValues={true} domain={domain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            enableSorting: true,
            enableColumnFilter: false,
            enableColumnActions: true,
            headerStyle: { borderBottom: '1px solid #ccc' },
        },
        {
            accessorKey: "empty2",
            cell: () => null,
            header: "",
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            size: 5,
            minSize: 1
        },
        {
            accessorKey: "detailed_scores",
            header: "Detailed score",
            Header: (column) => <BarChartHeader data={hdata} height={50} />,
            Cell: ({ cell }) => <StackedBarCell cell={cell} showValues={cell.row.id === hoveredRowId} domain={domain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false,
            headerStyle: { borderBottom: '1px solid #ccc' },
        },
    ]

    const table = useMantineReactTable({
        columns, data: rows,
        enableRowSelection: false,
        enableSelectAll: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        mantineTableHeadCellProps: ({ column }) => {
            if (column.id === 'mrt-row-select') {
                return {
                    style: {
                        width: '10px',
                        color: 'transparent',
                        padding: 0,
                        paddingBottom: 10,
                        verticalAlign: 'bottom'
                    },
                };
            }
            return {
                style: {
                    borderBottom:
                        column.id === 'indication.name'
                            ? '1px solid #000'
                            : column.id === 'm_score'
                                ? '1px solid #000'
                                : column.id === 'detailed_scores'
                                    ? '1px solid #000'
                                    : '0px',
                    padding: 0,
                    paddingBottom: 10,
                    verticalAlign: 'bottom'
                },
            };
        },
        mantineTableProps: {
            style: {
                borderCollapse: 'separate',
                borderSpacing: '0',
                tableLayout: "fixed",
            }
        },
        enableGlobalFilter: false,
        initialState: {
            showColumnFilters: true,
            density: 'xs',
            pagination: {
                pageIndex: 0,
                pageSize: 30
            },
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onMouseEnter: () => { setHoveredRowId(row.id) },
            onMouseLeave: () => { setHoveredRowId(null) },
        }),
        enableHiding: false,
        enableStickyHeader: true,
        renderTopToolbar: false,
        mantineBottomToolbarProps: {
            style: {
                marginBottom: "1px",
                padding: "5px"
            }
        },
        mantinePaperProps: {
            withBorder: false,
            shadow: 'initial',
            style: {
                flex: 1,
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            },
            // Make the table cells smaller
            className: css`
              .mantine-Table-td {
                padding: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            `
        },
        mantineTableContainerProps: {
            style: {
                overflow: 'hidden',
                scrollbarWidth: 'thin',
            },
            onMouseEnter: (e) => {
                e.currentTarget.style.overflow = 'auto';
            },
            onMouseLeave: (e) => {
                e.currentTarget.style.overflow = 'hidden';
            },
        },

    })

    const handleNext = () => {
        navigate('/evidence')
    }

    const handlePrev = () => {
        navigate('/')
    }

    return (
        <Stack gap={3}>
            <Group justify="center" style={{ padding: "1%" }}>
                <Group>
                    <ColorSwatch color="#2ca02c" size={20} />
                    <Text>Text Mining Overall</Text>
                </Group>
                <Group>
                    <ColorSwatch color="#9467bd" size={20} />
                    <Text>Text Mining Novelty</Text>
                </Group>
                <Group>
                    <ColorSwatch color="#ff7f0e" size={20} />
                    <Text>Differential Expression</Text>
                </Group>
                <Group>
                    <ColorSwatch color="#d62728" size={20} />
                    <Text>Open Targets Platform</Text>
                </Group>
            </Group>
            <div style={{ width: '100%' }}>
                <MantineReactTable table={table} />
            </div>
        </Stack>
    );
}

export default Rankings;
