import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { toPercentage } from '../../utils';

interface StackBarChartProps {
    data: { name: string, value: number, color: string }[];
    cwidth: number;
    cheight: number;
    domain?: [number, number];
    percentage?: boolean
    showValues?: boolean
}

const StackedBar: React.FC<StackBarChartProps> = ({ data, cwidth, cheight, domain = [-1, 1], percentage = true, showValues = true }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        // Derive width, height, and vcenter from props
        const padding = 5
        const width = cwidth;
        const height = cheight - padding;
        const vcenter = padding/2;

        // Clear the canvas
        context.clearRect(0, 0, cwidth, cheight);

        // Create an x scale that maps data values between -1 and 1 to the canvas width
        const xScale = d3.scaleLinear()
            .domain(domain)
            .range([0, width]);

        // Determine the zero position in canvas coordinates
        const zeroPosition = xScale(0);

        // Set font size and family
        context.font = '12px Arial'

        // Draw each bar segment as part of the stack
        let x0 = zeroPosition; // Start at the zero position
        let x1 = zeroPosition; // Start at the zero position
        data.forEach((d, i) => {
            const barWidth = xScale(Math.abs(d.value)) - zeroPosition;

            // Set the fill color
            context.fillStyle = d.color;

            // Draw the rectangle for each stacked segment
            if (d.value < 0) {
                // Negative values go left from the zero position
                context.fillRect(x1 - barWidth, vcenter, Math.abs(barWidth), height);
                if (showValues && d.value <= -0.01) {
                    // Add value inside the bar
                    context.fillStyle = 'white'; // Set text color
                    context.textAlign = 'center';
                    context.textBaseline = 'middle';
                    context.fillText(
                        percentage ? toPercentage(d.value) : d.value.toFixed(2),
                        x1 - barWidth / 2,
                        vcenter + height / 2
                    );
                }
                x1 -= barWidth;
            } else {
                // Positive values go right from the zero position
                context.fillRect(x0, vcenter, barWidth, height);
                if (showValues && d.value >= 0.01) {
                    // Add value inside the bar
                    context.fillStyle = 'white'; // Set text color
                    context.textAlign = 'center';
                    context.textBaseline = 'middle';
                    context.fillText(
                        percentage ? toPercentage(d.value) : d.value.toFixed(2),
                        x0 + barWidth / 2,
                        vcenter + height / 2
                    );
                }
                x0 += barWidth;
            }
        });

        // Draw the zero line
        context.beginPath();
        context.setLineDash([5, 5]);
        context.moveTo(zeroPosition, 0);
        context.lineTo(zeroPosition, cheight)
        context.strokeStyle = '#000';
        context.stroke();

    }, [data, cwidth, cheight, showValues]);

    return (
        <canvas
            ref={canvasRef}
            width={cwidth}
            height={cheight}
        />
    );
}

export default StackedBar;