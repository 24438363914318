import React, { useState } from 'react';
import { AppShell, Text, ScrollArea, Center } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import Search from './pages/Search';
import Evidence from './pages/Evidence';
import Rankings from './pages/Rankings';
import { NavLink } from './components/Navigation/NavLink';
import Header from './components/Navigation/Header';
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6';
import { MdBarChart } from 'react-icons/md';
import { RiPuzzle2Fill } from 'react-icons/ri';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { IconCaretLeftFilled, IconCaretRightFilled } from '@tabler/icons-react';

const CustomHandle = () => {
  return (
    <PanelResizeHandle>
      <div
        style={{
          display: 'flex',
          cursor: 'ew-resize',
        }}
      >
        <IconCaretLeftFilled size="15" color="#ddd" />
        <div
          style={{
            width: '1px',
            height: '100vh',
            backgroundColor: '#ddd'
          }}
        />
        <IconCaretRightFilled size="15" color="#ddd" />
      </div>
    </PanelResizeHandle>
  );
};

export function App() {
  const [meshOpened, setMeshOpened] = useState(false)
  const [selectedNavLink, setselectedNavLink] = useState(undefined)
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

  const onMeshSwitched = () => {
    setMeshOpened(!meshOpened)
  }

  return (
    <Router>
      <AppShell
        padding="md"
        styles={{
          main: {
            height: '100vh',
            overflowY: 'auto',
            background: 'white',
          },
        }}
        header={{ height: 100 }}
        transitionDuration={0}
        navbar={{
          width: !desktopOpened ? 52 : 250,
          breakpoint: 'sm',
          collapsed: { mobile: !mobileOpened },
        }}
      >
        <Header onMeshSwitched={onMeshSwitched} />
        <AppShell.Navbar style={{ backgroundColor: "#f3f3f7"}}>
          <AppShell.Section>
            <NavLink
              key="rankings"
              stage={{
                id: "rankings",
                name: "Rankings",
                description: "rankings",
                icon: MdBarChart
              }}
              open={desktopOpened}
              active={selectedNavLink === "rankings"}
              onClick={() => { setselectedNavLink("rankings") }}
            />
            <NavLink
              key="evidence"
              stage={{
                id: "evidence",
                name: "Evidence",
                description: "evidence",
                icon: RiPuzzle2Fill
              }}
              open={desktopOpened}
              active={selectedNavLink === "evidence"}
              onClick={() => { setselectedNavLink("evidence") }}
            />
          </AppShell.Section>
          <AppShell.Section
            style={{
              width: '100%',
              borderTop: '1px solid #e4e4e6',
              position: 'absolute',
              bottom: '0px',
            }}
          >
            <NavLink
              key="collapse"
              stage={{
                id: undefined,
                name: "Collapse",
                description: "",
                icon: desktopOpened ? FaAnglesLeft : FaAnglesRight
              }}
              open={desktopOpened}
              active={false}
              onClick={toggleDesktop}
            />
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
          <ScrollArea>
            <PanelGroup direction="horizontal">
              <Panel minSize={50} order={1}>
                <Routes>
                  <Route path="/" element={<Search />} />
                  <Route path="/rankings" element={<Rankings />} />
                  <Route path="/evidence" element={<Evidence />} />
                </Routes>
              </Panel>
              {meshOpened && (
                <>
                  <CustomHandle />
                  <Panel minSize={25} defaultSize={25} order={2}>
                    <Center style={{ height: '100%' }}>
                      <Text style={{ fontSize: '2rem' }} fw={700} c="#e4e4e6" ta="center">
                        MeSH
                      </Text>
                    </Center>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </ScrollArea>
        </AppShell.Main>
      </AppShell>
    </Router>
  );
}
