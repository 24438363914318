import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Container, Group, Text, Badge, Accordion, Avatar, ScrollArea, Stack, ColorSwatch } from "@mantine/core";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import diffexpr from "../../assets/data/diffexpr.json"
import txtmining from "../../assets/data/txtmining.json"
import otp from "../../assets/data/otp.json"
import DiffExprDetails from "../../components/EvidenceDetails/DiffExprDetails";
import TxtMiningDetails, { TxtMiningTypes } from "../../components/EvidenceDetails/TxtMiningDetails";
import OtpDetails from "../../components/EvidenceDetails/OtpDetails";
import SankeyChart from "../../components/EvidenceDetails/Sankey";
import scatter_icon from '../../assets/evidence/scatter.png'
import mining_icon from '../../assets/evidence/mining.png'
import otp_icon from '../../assets/evidence/otp.png'
import data from '../../assets/data/evidence.json'
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react";

enum DStreamTypes {
  txtMiningOverall = "txt_mining_overall",
  txtMiningNovelty = "txt_mining_novelty",
  DiffExpr = "deff_expr",
  OTP = "otp",
}

interface Filter {
  stream: DStreamTypes | undefined
  eid: number | undefined
  gene: string | undefined
  inline: boolean | undefined
}

export const LeftPanel = ({ data, onFilterSelected }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    setDimensions((prev) => ({
      ...prev,
      height: containerRef.current!.clientHeight,
    }));
  }, []);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDimensions((prev) => ({
          ...prev,
          width: entry.contentRect.width,
        }));
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  return (
    <Stack
      gap={3}
      ref={containerRef}
      style={{
        height: "100%",
        padding: "1%"
      }}
    >
      <Group justify="center">
        <Group>
          <ColorSwatch color="#2ca02c" size={20}/>
          <Text>Text Mining Overall</Text>
        </Group>
        <Group>
          <ColorSwatch color="#9467bd" size={20}/>
          <Text>Text Mining Novelty</Text>
        </Group>
        <Group>
          <ColorSwatch color="#ff7f0e" size={20}/>
          <Text>Differential Expression</Text>
        </Group>
        <Group>
          <ColorSwatch color="#d62728" size={20}/>
          <Text>Open Targets Platform</Text>
        </Group>
      </Group>
      <SankeyChart data={data} width={dimensions.width} height={dimensions.width} onRectSelected={onFilterSelected} />
    </Stack>
  );
};

const CustomHandle = () => {
  return (
    <PanelResizeHandle>
      <div
        style={{
          display: 'flex',
          cursor: 'ew-resize',
        }}
      >
        <IconCaretLeftFilled size="15" color="#ddd" />
        <div
          style={{
            width: '1px',
            height: '100vh',
            backgroundColor: '#ddd'
          }}
        />
        <IconCaretRightFilled size="15" color="#ddd" />
      </div>
    </PanelResizeHandle>
  );
};

interface AccordionLabelProps {
  label: string;
  image: string;
  color: string;
}

function AccordionLabel({ label, image, color }: AccordionLabelProps) {
  return (
    <Group wrap="nowrap">
      <Avatar src={image} radius="xs" size="md" />
      <Badge size="lg" color={color}>{label}</Badge>
    </Group>
  );
}

export const RightPanel: React.FC<{ filters: Filter[] }> = ({ filters }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    setDimensions((prev) => ({
      ...prev,
      height: containerRef.current!.clientHeight,
    }));
  }, []);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDimensions((prev) => ({
          ...prev,
          width: entry.contentRect.width,
        }));
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  const has_stream = (stream) => filters.find(f => f.stream === stream)

  return (
    <div
      ref={containerRef}
      style={{
        height: "90%",
        marginBottom: "200px"
      }}
    >
      <Accordion value={filters.every(f => f.stream === filters[0].stream) ? filters[0].stream : undefined}>
        {
          (has_stream(DStreamTypes.DiffExpr)) &&
          <Accordion.Item key={DStreamTypes.DiffExpr} value={DStreamTypes.DiffExpr}>
            <Accordion.Control><AccordionLabel image={scatter_icon} label="Differential Expression" color="#ff7f0e" /></Accordion.Control>
            <Accordion.Panel
              style={{
                margin: 0,
                padding: 0
              }}>
              <DiffExprDetails
                key={`diffexpr-${filters}`}
                data={diffexpr}
                filters={filters}
                width={dimensions.width}
                height={0.7 * dimensions.width} />
            </Accordion.Panel>
          </Accordion.Item>
        }
        {
          (has_stream(DStreamTypes.txtMiningNovelty)) &&
          <Accordion.Item key={DStreamTypes.txtMiningNovelty} value={DStreamTypes.txtMiningNovelty}>
            <Accordion.Control><AccordionLabel image={mining_icon} label="Text Mining Novelty" color="#9467bd" /></Accordion.Control>
            <ScrollArea>
              <Accordion.Panel
                style={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  minHeight: 250
                }}>
                <TxtMiningDetails
                  key={`txtmining-novelty-${filters}`}
                  type={TxtMiningTypes.novelty}
                  data={txtmining}
                  filters={filters}
                  width={dimensions.width}
                  height={0.5 * dimensions.width} />
              </Accordion.Panel>
            </ScrollArea>
          </Accordion.Item>
        }
        {
          (has_stream(DStreamTypes.txtMiningOverall)) &&
          <Accordion.Item key={DStreamTypes.txtMiningOverall} value={DStreamTypes.txtMiningOverall}>
            <Accordion.Control><AccordionLabel image={mining_icon} label="Text Mining Overall" color="#2ca02c" /></Accordion.Control>
            <ScrollArea>
              <Accordion.Panel
                style={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  minHeight: 250
                }}>
                <TxtMiningDetails
                  key={`txtmining-overall-${filters}`}
                  type={TxtMiningTypes.overall}
                  data={txtmining}
                  filters={filters}
                  width={dimensions.width}
                  height={0.5 * dimensions.width} />
              </Accordion.Panel>
            </ScrollArea>
          </Accordion.Item>
        }
        {
          (has_stream(DStreamTypes.OTP)) &&
          <Accordion.Item key={DStreamTypes.OTP} value={DStreamTypes.OTP}>
            <Accordion.Control><AccordionLabel image={otp_icon} label="Open Targets Platform" color="#d62728" /></Accordion.Control>
            <ScrollArea>
              <Accordion.Panel
                style={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  minHeight: 250,
                  maxWidth: dimensions.width
                }}>
                <OtpDetails
                  key={`otp-${filters}`}
                  data={otp}
                  filters={filters}
                  width={dimensions.width}
                  height={500} />
              </Accordion.Panel>
            </ScrollArea>
          </Accordion.Item>
        }
      </Accordion>
    </div>
  );
};

const Evidence: React.FC = () => {
  const [selectedFilter, setSelectedFilter] = useState([])

  const onFilterSelected = (links) => {
    if (links.length === 0) {
      setSelectedFilter([])
      return
    }
    let filters = []
    links.forEach(l => {
      filters.push({ ...l.filters, ...l.target.filters })
    });
    setSelectedFilter(filters)
  }

  return (
        <Container fluid style={{ display: "flex", height: "100vh", padding: 0 }}>
          <PanelGroup direction="horizontal">
            <Panel minSize={50} order={1} >
              <LeftPanel data={data} onFilterSelected={onFilterSelected} />
            </Panel>
            {selectedFilter.length > 0 && (
              <>
                <CustomHandle />
                <Panel minSize={25} defaultSize={25} order={2}>
                  <RightPanel filters={selectedFilter} />
                </Panel>
              </>
            )}
          </PanelGroup>
        </Container>
  );
};

export default Evidence;
