import React, {  } from 'react'

import { css } from '@emotion/css'
import { Avatar, Box, Group, Text, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core'
import { Link } from 'react-router-dom'
import { IconType } from 'react-icons'

interface Istage {
    id: string
    name: string
    description?: string
    icon?: IconType;
}

export function NavLink({ stage, active, open, onClick }: { stage: Istage; open: boolean; active: boolean; onClick?: () => any}) {
    const theme = useMantineTheme();
    const color = theme.primaryColor;

    return (
        <Box className="mantine-NavLink">
            <UnstyledButton
                component= {stage.id !== undefined? Link:'a'}
                to={stage.id !== undefined? `/${stage.id}` : undefined}
                onClick={onClick}
                className={css`
          display: block;
          width: 100%;
          padding: ${theme.spacing.xs} ${theme.spacing.xs};
          color: #545572;
          &:hover:not(:disabled) {
            background-color: ${theme.colors.gray[0]};
            color: #484966;
          }
          :disabled {
            cursor: default;
            color: ${theme.colors.gray[6]};
          }
        `}>
                <Group justify="space-between" align="center" gap={0} wrap="nowrap">
                    <Group align="center" wrap="nowrap" style={{ overflowX: 'auto' }}>
                        <Tooltip disabled={open || !!stage.description} label={stage.name} withArrow position="right">
                            <Avatar
                                radius="xl"
                                color={active ? 'dark' : 'gray.6'}
                                size={30}
                                gradient={{
                                    deg: 45,
                                    from: theme.colors[color][6],
                                    to: theme.colors[color][3],
                                }}
                            >
                                {stage.icon ? <stage.icon  size={20}/> : open ? null : stage.name.charAt(0)}
                            </Avatar>
                        </Tooltip>
                        {open ? (
                            <Text truncate fw={500} size="sm">
                                {stage.name}
                            </Text>
                        ) : null}
                    </Group>
                </Group>
            </UnstyledButton>
        </Box>
    );
}